<template>
  <el-dialog :title="editData.id?'编辑':'新增'" :visible.sync="_show" width="800px" >
    <el-form :model="editData" :rules="editStaffRules" ref="staffEditForm" size="medium">
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            <el-input maxlength="10" v-model.trim="editData.name" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="电话" prop="mobile">
            <el-input maxlength="20" v-model.trim="editData.mobile" placeholder="请输入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="editData.status">
              <el-radio :label="true">启用</el-radio>
              <el-radio :label="false">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="maintain-config">
      <el-tabs v-model="activeTab" type="card">
        <el-tab-pane label="维修职能">
           <div class="config-block">
             <el-input
                 class="area-filter-input"
                 size="medium"
                 placeholder="输入关键字，按回车键搜索"
                 @change="getRepairItems"
                 v-model="filterType">
             </el-input>
             <div class="block-left border">
               <div class="config-group clearfix">
                 <label>职能分配</label>
                 <div class="select-block">
                   <el-checkbox class="pdl8" size="medium" v-model="editData.hasAllType" @change="selectAllType">全能</el-checkbox>
                 </div>
               </div>
               <div class="config-group clearfix">
                 <label></label>
                 <el-tree class="select-block" show-checkbox
                          check-strictly
                          :data="repairItems"
                          :props="{children:'child',label:'typeName'}"
                          node-key="id"
                          ref="typeTree"
                          @check-change="selectNode"
                          :default-expanded-keys="editData.types"
                          :default-checked-keys="editData.types"
                          highlight-current>
                 </el-tree>
               </div>
             </div>
             <div class="block-right" v-if="seletedType.length">
               <label class="right-label">{{`已选择${seletedType.length}项`}}</label>
               <div class="config-group border">
                 <div class="selected-area">
                   <div class="selected-item" v-for="(selected,index) in seletedType">
                     {{selected.typeName}}
                     <span class="del-icon" @click="delSelect(seletedType,selected,index)">×</span>
                   </div>
                 </div>
               </div>
             </div>
           </div>
        </el-tab-pane>
        <el-tab-pane label="维修区域">
          <div class="config-block">
              <el-input
                  class="area-filter-input"
                  size="medium"
                  placeholder="输入关键字，按回车键搜索"
                  @change="getAreas"
                  v-model="filterArea">
              </el-input>
            <div class="block-left border">
              <div class="config-group">
                <label>区域分配</label>
                <el-tree class="area-tree" show-checkbox
                         check-strictly
                         :data="areas"
                         :props="{children:'child',label:'areaName'}"
                         node-key="id"
                         ref="areaTree"
                         :default-checked-keys="editData.areas"
                         :default-expanded-keys="editData.areas"
                         @check-change="selectNode"
                         highlight-current>
                </el-tree>
              </div>
            </div>
            <div class="block-right" v-if="seletedArea.length">
              <label class="right-label">{{`已选择${seletedArea.length}项`}}</label>
              <div class="config-group border">
                <div class="selected-area">
                  <div class="selected-item" v-for="(selected,index) in seletedArea">
                    {{selected.areaName}}
                    <span class="del-icon" @click="delSelect(seletedArea,selected,index)">×</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer">
        <el-button @click="_show=false" size="medium">取消</el-button>
        <el-button  type="primary" @click="confirm" size="medium">确定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import {validatePhone} from "@/utils/validator";
import {validateSpeCharacter} from "@/utils/validator";

export default {
  name: "maintenance-staff-edit",
  props:{
    show:{
      type:Boolean,
      default(){
        return false
      }
    },
    edit:{
      type:Object,
      default() {
        return {}
      }
    }
  },
  watch:{
    _show(newVal){
      if(newVal){
        this.$refs.staffEditForm.clearValidate()
      }
    },
    activeTab(){
      this.selectNode()
    }
  },
  data(){
    return{
      editStaffRules:{
        name:[this.$formValidator.empty('姓名','blur'),{validator:validateSpeCharacter,trigger:'blur'}],
        mobile:[this.$formValidator.empty('电话','blur'),{validator:validatePhone,trigger: 'blur'}]
      },
      repairItems:[],
      areas:[],
      activeTab:"0",
      filterArea:'',
      filterType:'',
      seletedArea:[],
      seletedType:[]
    }
  },
  computed:{
    _show:{
      get(){
        return this.show;
      },
      set(val){
        this.$emit("update:show",val)
      }
    },
    editData:{
      get(){
        return this.edit;
      },
      set(val){
        this.$emit("update:edit",val)
      }
    }

  },
  mounted(){
    this.init();
  },

  methods:{
    async init(){
       await this.getRepairItems();
       await this.getAreas();
      if(this.editData.id){
        this.getDetail()
      }
    },

    getDetail(){
      this.$api.property.getDetailById({id:this.editData.id}).then(res=>{
        this.editData = res.data;
        this.$nextTick(()=>{
          this.selectAllType(this.editData.hasAllType)
          this.selectNode()
        })
      })
    },

    confirm(){
      this.$refs.staffEditForm.validate(valid=>{
        if(valid){
          if(this.editData.hasAllType){
            this.editData.types = [];
          }else{
            this.editData.types = this.$refs.typeTree.getCheckedNodes().map(type=>type.id)||[];
            if(!this.editData.types.length){
              this.$message.warning('请分配职能！');
              return ;
            }
          }
          this.editData.areas = this.$refs.areaTree.getCheckedNodes().map(area=>area.id)||[];
          this.editData.canReceiveOrder =  this.editData.status?this.editData.canReceiveOrder:false
          const handler = this.editData.id?this.$api.property.editRepairUser(this.editData):this.$api.property.addRepairUser(this.editData)
          handler.then(res=>{
            if(res.code===200){
              this.$message.success('操作成功');
              this._show=false;
              this.$parent.search();
            }
          })
        }
      })

    },
    // 获取报修项
    getRepairItems(){
      return new Promise((resolve)=>{
        this.$api.property.filterRepairItem({typeName:this.filterType}).then(res=>{
          this.repairItems = res.data;
          resolve(this.repairItems)
        })
      })
    },

    getAreas(){
      return new Promise((resolve)=>{
        this.$api.property.filterArea({areaName:this.filterArea}).then(res=>{
          this.areas = res.data;
          resolve(this.areas)
        })
      })
    },

    disabledTree(obj,isDisabled){
      for(let itr of obj){
        this.$set(itr,'disabled',isDisabled)
        if(itr.child){
          this.disabledTree(itr.child,isDisabled)
        }
      }
    },

    // 选择全能
    selectAllType(val){
      if(val){
       this.$refs.typeTree.setCheckedNodes([])
       this.disabledTree(this.repairItems,true)
      }else{
        this.disabledTree(this.repairItems,false)
      }
    },

    selectNode(){
      this.$nextTick(()=>{
        if(this.activeTab=='1'){
          this.seletedArea = this.$refs.areaTree.getCheckedNodes()
        }else{
          this.seletedType = this.$refs.typeTree.getCheckedNodes()
        }
      })
    },

    delSelect(target,item,index){
      target.splice(index,1)
      const treeDom = ['typeTree','areaTree']
      this.$nextTick(()=>{
        this.$refs[treeDom[parseInt(this.activeTab)]].setCheckedNodes(this.$refs[treeDom[parseInt(this.activeTab)]].getCheckedNodes().filter(itr=>(itr.id!==item.id)))
      })
    }

  }
}
</script>

<style scoped lang="scss">
.el-input,.el-select {
  width: 220px;
}
.area-filter-input{
  width: 58% !important;
}
::v-deep .el-dialog__body{
  min-height: 546px;
}
.el-divider--horizontal{
  margin: 0 0 10px 0;
}
::v-deep .el-checkbox__label{
  font-size: 14px;
}
::v-deep .el-form-item__error{
  padding-left: 50px;
}
.maintain-config{
  .config-block{
    padding: 12px;
    .config-group{
      overflow: hidden;
      >label{
        width: 65px;
        vertical-align: text-top;
      }

      .select-block,.area-tree{
        vertical-align: text-top;
        display: inline-block;
        width: calc(100% - 90px);
        .select-group{
          .select-label{
            display: inline-block;
            padding: 9px 20px 9px 5px;
            vertical-align: middle;
            font-weight: bold;
          }
          .checkbox-all{
            vertical-align: middle;
          }
          padding: 10px 0;
        }
      }

    }
  }
}
.area-tree{
  padding: 5px 0;
}
.block-left{
  width: 58%;
  float: left;
  padding: 5px;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
}
.block-right{
  width: 40%;
  float: right;
  height: 360px;
  overflow-y: auto;
  overflow-x: hidden;
  .right-label{
    position: absolute;
    top: 15px;
    right: 20px;
  }

}
.selected-item{
  line-height: 1.5;
  position: relative;
  cursor: pointer;
  border-top: 1px solid #e3e3e3;
  padding: 8px 12px;
  .del-icon{
    position: absolute;
    right: 10px;
    height: 15px;
    line-height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #9f9d9d;
    color: #fff;
    text-align: center;
  }
}
.border{
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}
</style>